export const MEETING_REQUEST_DASHBOARD_FRAGMENT = `
fragment meetingRequestDashboardFragment on MeetingRequest {
  uid
  createdTime
  createdTimestamp
  readState
  exhibitor {
    uid
  }
  user {
    id
    uid
    schemaCode
    firstName
    lastName
    name
    prefix
    suffix
    jobTitle
    employerName
    profilePrivacyMode
    pictureFileResource {
      path
      schemaCode
    }
  }
}`;
